import dayjs from "dayjs";
import { TbMessage } from "solid-icons/tb";
import { Show } from "solid-js";
import { classNames } from "~/lib/classNames";
import styles from "./LargeChatCTA.module.css";
import type { CTAProps } from "./types";

interface ButtonProps extends CTAProps {
  label: string;
  accessiblePrefix?: string;
  accessibleSuffix?: string;
  lastModified?: string;
  modifier?: "surface"; // | "background";
}

type Props = RequireAtLeastOne<ButtonProps, "accessiblePrefix" | "accessibleSuffix">;

export const LargeChatCTA = (props: Props) => {
  return (
    <button
      type="button"
      class={classNames(
        styles["large-chat-cta"],
        props.class,
        props.modifier ? styles[`large-chat-cta--${props.modifier}`] : "",
      )}
      tabIndex={0}
      on:click={props.onClick}
    >
      <span class={styles["large-chat-cta__icon-container"]}>
        <TbMessage class={styles["large-chat-cta__icon"]} size="1.25rem" />
      </span>
      <Show when={props.accessiblePrefix}>
        <span class="screen-reader">{props.accessiblePrefix}</span>
      </Show>
      <span class={styles["large-chat-cta__label"]}>{props.label}</span>
      <Show when={props.accessibleSuffix}>
        <span class="screen-reader">{props.accessibleSuffix}</span>
      </Show>
      <Show when={props.lastModified}>
        <span class="screen-reader">Last updated</span>
        <span class={styles["large-chat-cta__age"]}>{dayjs(props.lastModified).format("LL")}</span>
      </Show>
    </button>
  );
};
