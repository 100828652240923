import type { projects } from "@repo/client";
import dayjs from "dayjs";
import { TbAlertCircle } from "solid-icons/tb";
import { TiTick } from "solid-icons/ti";
import { For, Match, Switch, createMemo, createSignal } from "solid-js";
import { sortObjectArray } from "~/lib/array/sortObjectArray";
import { classNames } from "~/lib/classNames";
import { readableProcessingState } from "~/lib/ui/readableProcessingState";
import { Spinner } from "../Loaders";
import { StIcon } from "../icons";
import styles from "./CollectionAssetTable.module.css";
import { InlineAsset } from "./InlineAsset";
import { InlineAssetSummary } from "./InlineAssetSummary";

type Sort<T> = {
  field: keyof T;
  dir: SortDirection;
};

interface Props {
  collectionName: string;
  data: projects.AssetSnapshot[];
}

export const CollectionAssetsTable = (props: Props) => {
  const [sortOrder, setSortOrder] = createSignal<Sort<projects.AssetSnapshot>>({
    field: "modifiedAt",
    dir: "desc",
  });
  const data = createMemo(() =>
    sortObjectArray<projects.AssetSnapshot>(sortOrder().field, props.data, sortOrder().dir),
  );

  const onClick = (property: keyof projects.AssetSnapshot) => {
    return () => {
      if (sortOrder().field === property) {
        setSortOrder({
          field: property,
          dir: sortOrder().dir === "asc" ? "desc" : "asc",
        });
      } else {
        setSortOrder({
          field: property,
          dir: "asc",
        });
      }
    };
  };
  return (
    <div class={styles.table} data-component="collection-assets-table">
      <table class={styles.table__container}>
        <caption class="screen-reader">{`Table representing the assets associated with the ${props.collectionName} collection.`}</caption>
        <thead class={styles.table__header}>
          <tr class={styles.table__row}>
            <th class={styles["table__header-cell"]}>
              <button
                type="button"
                class={classNames(
                  styles["table__header-button"],
                  sortOrder().field === "displayName"
                    ? sortOrder().dir === "asc"
                      ? styles["table__header-button--asc"]
                      : styles["table__header-button--desc"]
                    : "",
                )}
                aria-label="Sort by name"
                onClick={onClick("displayName")}
              >
                <span class="screen-reader">Sort table by</span>
                <span class={styles["table__header-label"]}>Name</span>
                <span class={styles["table__sort-indicator"]} />
              </button>
            </th>
            <th class={styles["table__header-cell"]}>
              <button
                type="button"
                class={classNames(
                  styles["table__header-button"],
                  sortOrder().field === "lifecycleState"
                    ? sortOrder().dir === "asc"
                      ? styles["table__header-button--asc"]
                      : styles["table__header-button--desc"]
                    : "",
                )}
                aria-label="Sort by file size"
                onClick={onClick("lifecycleState")}
              >
                <span class="screen-reader">Sort table by</span>
                <span class={styles["table__header-label"]}>Status</span>
                <span class={styles["table__sort-indicator"]} />
              </button>
            </th>
            <th class={styles["table__header-cell"]}>
              <button
                type="button"
                class={classNames(
                  styles["table__header-button"],
                  sortOrder().field === "originalFilename"
                    ? sortOrder().dir === "asc"
                      ? styles["table__header-button--asc"]
                      : styles["table__header-button--desc"]
                    : "",
                )}
                aria-label="Sort by filename"
                onClick={onClick("originalFilename")}
              >
                <span class="screen-reader">Sort table by</span>
                <span class={styles["table__header-label"]}>Source</span>
                <span class={styles["table__sort-indicator"]} />
              </button>
            </th>
            <th class={styles["table__header-cell"]}>
              <button
                type="button"
                class={classNames(
                  styles["table__header-button"],
                  sortOrder().field === "modifiedAt"
                    ? sortOrder().dir === "asc"
                      ? styles["table__header-button--asc"]
                      : styles["table__header-button--desc"]
                    : "",
                )}
                aria-label="Sort by file size"
                onClick={onClick("modifiedAt")}
              >
                <span class="screen-reader">Sort table by</span>
                <span class={styles["table__header-label"]}>Last modified</span>
                <span class={styles["table__sort-indicator"]} />
              </button>
            </th>
            {/* <th class={styles.table__cell}>
              <span class={styles["table__header-label"]} />
            </th> */}
          </tr>
        </thead>
        <tbody class={styles.table__body} aria-busy={false}>
          <For each={data()}>
            {(item) => {
              return (
                <tr class={styles.table__row} tabIndex={0}>
                  <td class={styles.table__cell}>
                    <InlineAssetSummary summary={item.displayName} />
                  </td>
                  <td class={styles.table__cell}>
                    <Switch>
                      <Match when={readableProcessingState(item.lifecycleState) === "Failed"}>
                        <p class={classNames(styles["table__cell-status"], styles["table__cell-status--failed"])}>
                          <StIcon icon={TbAlertCircle} size="1.25rem" />
                          <span class={styles["table__cell-status-text"]}>
                            {readableProcessingState(item.lifecycleState)}
                          </span>
                        </p>
                      </Match>
                      <Match when={readableProcessingState(item.lifecycleState) === "Processing"}>
                        <p class={classNames(styles["table__cell-status"], styles["table__cell-status--processing"])}>
                          <Spinner class={styles["table__cell-status--processing"]} />
                          <span class={styles["table__cell-status-text"]}>
                            {readableProcessingState(item.lifecycleState)}
                          </span>
                        </p>
                      </Match>
                      <Match when={readableProcessingState(item.lifecycleState) === "Ready"}>
                        <p class={classNames(styles["table__cell-status"], styles["table__cell-status--ready"])}>
                          <StIcon icon={TiTick} size="1.25rem" />
                          <span class={styles["table__cell-status-text"]}>
                            {readableProcessingState(item.lifecycleState)}
                          </span>
                        </p>
                      </Match>
                    </Switch>
                  </td>
                  <td class={styles.table__cell}>
                    <InlineAsset
                      name={item.originalFilename}
                      mimeType={item.originalFilename?.split(".").pop()?.toLowerCase() || ""}
                    />
                  </td>
                  <td class={styles.table__cell}>
                    <span class={styles["table__cell-text"]}>{dayjs(item.modifiedAt).format("LL")}</span>
                  </td>
                  {/* <td class={styles.table__cell} /> */}
                </tr>
              );
            }}
          </For>
        </tbody>
      </table>
    </div>
  );
};
