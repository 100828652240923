import type { AssetLifecycleState } from "@repo/client";
import dayjs from "dayjs";
import { readableProcessingState } from "../ui/readableProcessingState";

/**
 * **sortObjectArray**
 *
 * Sort an array of objects by a given object key.
 *
 * @example
 * ```ts
 * type Data = { key: string };
 * const data = [{ key: "value1" }, {key: "value2"} ];
 * const sorted = sortObjectArray<Data>("key", data, "asc");
 * ```
 */
export const sortObjectArray = <T>(property: keyof T, data: T[], direction: SortDirection): T[] => {
  const multiplier = direction === "asc" ? 1 : -1;

  return [...data].sort((a, b) => {
    if (property === "modifiedAt") {
      const valueA = dayjs(a[property] as string);
      const valueB = dayjs(b[property] as string);
      return multiplier * valueA.diff(valueB);
    }

    if (property === "lifecycleState") {
      const valueA = readableProcessingState(a[property] as AssetLifecycleState[keyof AssetLifecycleState]);
      const valueB = readableProcessingState(b[property] as AssetLifecycleState[keyof AssetLifecycleState]);
      console.log("Sorting...", valueA, valueB);
      return multiplier * valueA.localeCompare(valueB);
    }

    const valueA = a[property];
    const valueB = b[property];
    if (typeof valueA === "string" && typeof valueB === "string") {
      return multiplier * valueA.localeCompare(valueB);
    }
    return multiplier * (Number(valueA) - Number(valueB));
  });
};
